import React  from "react";


const ImageComponent = (props) => {

    //const [loaded,setLoad] = useState(false);

    return(<>
        {/* {!loaded && <Skeleton variant="rounded" className="skeleton"/>} */}
        <img className="image" 
        loading="lazy" 
        //onLoad={()=>{setLoad(true)}} 
        {...props}/>
    </>)
}

export default ImageComponent;